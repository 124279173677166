<template>
  <div class="login-page">
    <div class="top">
      <span>多元数据分析系统</span>
    </div>
    <div class="content">
      <div class="form-box">
        <p class="session-title">登 录</p>
        <el-form
          ref="loginform"
          :rules="rules"
          :model="form"
          label-position="top"
        >
          <el-form-item label="用户名" prop="account_name">
            <el-input
              v-model.trim="form.account_name"
              placeholder="用户名"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              type="password"
              v-model.trim="form.password"
              show-password
              placeholder="密码"
            ></el-input>
          </el-form-item>
          <div style="margin-top:20px">
            <el-checkbox v-model="checked">记住密码?</el-checkbox>
          </div>
          <el-button
            class="btn"
            :disabled="!loginFlag"
            @click.prevent="handleLogin"
            type="success"
          >
            登 录
          </el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { accountPermission } from '@/api/manage'
import { Loading, Message } from 'element-ui'
export default {
  data() {
    return {
      form: {
        account_name: process.env.VUE_APP_ACCOUNT_NAME,
        password: process.env.VUE_APP_PASSWORD
      },
      checked: false,
      rules: {
        account_name: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.loginFlag()
  },
  methods: {
    loginFlag() {
      return !(
        this.form.account_name.length === 0 || this.form.password.length === 0
      )
    },
    handleLogin() {
      this.$refs['loginform'].validate(valid => {
        if (valid) {
          if (this.checked) {
            window.localStorage.setItem(
              'usernameitemdasd',
              this.form.account_name
            )
            window.localStorage.setItem('paswfegwqeqweqw', this.form.password)
          } else {
            window.localStorage.setItem('usernameitemdasd', '')
            window.localStorage.setItem('paswfegwqeqweqw', '')
          }
          // const loadingInstance = Loading.service({ fullscreen: true })
          this.$store.dispatch('user/loginPage', this.form).then(res => {
            sessionStorage.setItem('websiteSource', '/login')
            // loadingInstance.close()
            this.$store.dispatch('user/getTask').then(res => {
              if (res.status == 200) {
                this.$router.push('/')
              }
            })
            accountPermission().then(res => {
              if (res.status == 200) {
                let arr = res.data.data.permissions_str_list
                if (arr.indexOf('任务调度') > -1) {
                  sessionStorage.setItem('juHeRoles', true)
                }
              }
            })
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.login-page {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .top {
    width: 100%;
    height: 70px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    span {
      font-size: 28px;
      color: rgb(152, 158, 158);
      line-height: 70px;
      margin-left: 50px;
    }
  }
  .content {
    width: 100%;
    height: calc(100% - 70px);
    position: relative;
    .form-box {
      p {
        color: #33393c;
        font-weight: 400;
        text-transform: capitalize;
        font-size: 21px;
        text-align: center;
      }
      width: 20%;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
      .btn {
        background-color: #83e5d9;
        border: #83e5d9;
        width: 100%;
      }
    }
  }
}
</style>
